// src/components/Activities.js

import React from "react";
import "./Activities.css";

const Activities = () => {
  return (
    <div className="activities-section">
      <h2>Activities to Enjoy</h2>
      <div className="activities-container">
        <div className="activity">
          <img
            src="https://s3.amazonaws.com/lws_lift/timbercreek_trinity_hills/images/blog/1596138779_hiking_-_resized.jpg"
            alt="Hiking"
          />
          <h3>Hiking</h3>
          <p>
            Explore the beautiful trails around the homestay with guided hiking
            tours.
          </p>
        </div>
        <div className="activity">
          <img
            src="https://visitidaho.org/content/uploads/2015/09/kids-fishing.jpg"
            alt="Fishing"
          />
          <h3>Fishing</h3>
          <p>
            Relax by the serene lake and enjoy a peaceful fishing experience.
          </p>
        </div>
        <div className="activity">
          <img
            src="https://i0.wp.com/www.tusktravel.com/blog/wp-content/uploads/2022/11/Birdwatching-1.jpg?w=2500&ssl=1"
            alt="Bird Watching"
          />
          <h3>Bird Watching</h3>
          <p>Spot rare and exotic birds in their natural habitat.</p>
        </div>
        <div className="activity">
          <img
            src="https://1.bp.blogspot.com/-0hmOyu6hTAo/VXYfUUfoCTI/AAAAAAAAC_0/Hiy4P45oumI/s1600/solstice%2Bfire%2B072.jpg"
            alt="Campfire"
          />
          <h3>Campfire</h3>
          <p>
            Gather around a warm campfire and share stories under the stars.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Activities;
