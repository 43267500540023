import React from "react";
import "./Testimonials.css";

const testimonials = [
  {
    id: 1,
    name: "Jane Doe",
    text: "This homestay was a perfect escape from the city. The peaceful surroundings and cozy accommodations made for a wonderful stay.",
  },
  {
    id: 2,
    name: "John Smith",
    text: "A beautiful place with fantastic service. Highly recommended for anyone looking for a relaxing getaway.",
  },
  {
    id: 3,
    name: "Alice Johnson",
    text: "The attention to detail and the warmth of the hosts made our stay unforgettable. Will definitely return!",
  },
  {
    id: 4,
    name: "Jane Doe",
    text: "This homestay was a perfect escape from the city. The peaceful surroundings and cozy accommodations made for a wonderful stay.",
  },
  {
    id: 5,
    name: "John Smith",
    text: "A beautiful place with fantastic service. Highly recommended for anyone looking for a relaxing getaway.",
  },
  {
    id: 6,
    name: "Alice Johnson",
    text: "The attention to detail and the warmth of the hosts made our stay unforgettable. Will definitely return!",
  },
  // Add more testimonials as needed
];

const Testimonials = () => {
  const duplicatedTestimonials = [...testimonials, ...testimonials]; // Duplicate testimonials for seamless scroll

  return (
    <div className="testimonials">
      <h2>What Our Guests Say</h2>
      <div className="testimonials-container">
        {duplicatedTestimonials.map((testimonial) => (
          <div key={testimonial.id} className="testimonial">
            <p>"{testimonial.text}"</p>
            <h3>- {testimonial.name}</h3>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Testimonials;
