import React from "react";
import "./About.css";
import NavigationBar from "./Navbar";

const About = () => {
  return (
    <>
      <NavigationBar />
      <div className="about-container">
        <div className="about-content">
          <h1>About Our Homestay</h1>
          <p>
            Nestled in the heart of nature, our homestay offers a perfect blend
            of tranquility and comfort. Whether you're looking to escape the
            hustle and bustle of the city or explore the local culture, our
            homestay provides an ideal base for your adventures.
          </p>
        </div>
        <div className="gallery-container">
          <h2>Gallery</h2>
          <div className="gallery-grid">
            <img
              src="http://thealleppeyhouseboat.com/img/portfolio/g1.jpg"
              alt="Cozy Room"
              className="gallery-image"
            />
            <img
              src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fcf.bstatic.com%2Fxdata%2Fimages%2Fhotel%2Fmax1024x768%2F36924366.jpg%3Fk%3Dcd327d5fc634e9eff122c4917c3600e0ab631a32bb71e62d1f45453cc7ebd76a%26o%3D%26hp%3D1&f=1&nofb=1&ipt=fc7180b4345339842ac558f33126b40558247beaac5f4df04cff767728a518d8&ipo=images"
              alt="Scenic View"
              className="gallery-image"
            />
            <img
              src="https://amazemytrip.com/wp-content/uploads/2019/07/42c670d7-9102-4518-b308-32ef2b4a0176.jpg"
              alt="Outdoor Area"
              className="gallery-image"
            />
            <img
              src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fi.ytimg.com%2Fvi%2FBSWFngNEFTE%2Fmaxresdefault.jpg&f=1&nofb=1&ipt=d02b9212371a0f1b4b47db6f4a3e6488babe03094dd939b0494531c20574c2cc&ipo=images"
              alt="Dining Area"
              className="gallery-image"
            />
            <img
              src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftrisoj.com%2Ftravel-guide%2Fwp-content%2Fuploads%2F2022%2F09%2FSpa-and-Massage-in-Alleppey-525x300.jpg&f=1&nofb=1&ipt=bb1e8d56523e40424329c53e1ffa9abcee2cb82b620970d416bb8c953f5d244c&ipo=images"
              alt="Spa Area"
              className="gallery-image"
            />
            <img
              src="https://media.weddingz.in/images/c7b412bd1c4595276c6f7298b115f315/lake-palace-resort-alleppey-alleppey-1.jpg"
              alt="Reception"
              className="gallery-image"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
